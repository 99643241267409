.ad {
    /* border: 1px solid #999797; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: calc(100vw - 4px);
    height: calc(100vh - 4px);
}

.corner-buttons {
    float: right;
    margin-bottom: -36.2px;
    perspective: 100px;
    transform: translate(-10px, 10px);
    display: flex;
}

.corner-buttons button {
    margin-left: 10px;
    padding: 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.corner-buttons button:hover {
    background-color: #2980b9;
}

/* Adjust the styles for the 'Ad' button */
.corner-buttons .ad-button {
    background-color: #e74c3c;
}

.corner-buttons .ad-button:hover {
    background-color: #c0392b;
}

.powered {
    width: calc(100vw - 4px);
    height: calc(100vh - 4px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#imgss{
    width: calc(100vw - 4px);
    height: calc(100vh - 4px);
    display: flex;
    justify-content: center;
    align-items: center;
}